import React, {Fragment} from 'react';
import { Box, Container, Grid, Link, List, ListItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SocialAccounts from '@/components/01_atoms/SocialAccounts/SocialAccounts'
import Logo from '@/components/01_atoms/Logo/Logo'
import FooterBanner from '@/components/02_molecules/FooterBanner/FooterBanner'
import {useRouter} from "next/router";

const useStyles = makeStyles((theme) => ({
  topGrid: {
    [theme.breakpoints.down("sm")]: {
      margin: '1rem 0'
    }
  },
  boxCenter: {
    [theme.breakpoints.down("sm")]: {
      alignItems: 'center',
      padding: 0,
      marginLeft: 0,
    }
  },
  bodyLogo: {
    '& div': {
      margin: 0,
      display: 'flex',
      justifyContent: 'center',
      "& img": {
        padding: '.5rem 1rem 1rem 1rem',
        backgroundColor: '#fff',
      }
    }
  },
  topLogo: {
    '& div': {
      margin: 0,
    },
  },
  footerTop: {
    '& a:hover': {
      backgroundColor: 'rgba(0,0,0,.2)',
    },
    '& h6': {
      color: 'white',
      textAlign: 'center',
      fontSize: 26,
      fontWeight: 700,
      padding: '1rem',
      '& span': {
        fontFamily: 'Lato',
        fontWeight: 400,
        fontSize: 22,
      },
    },
    '& .MuiGrid-item': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& img': {
        padding: 0,
      }
    }
  },
  footerCopyright: {
    color: '#fff',
    fontSize: 16,
  },
  footerMenuContent: {
    fontSize: 16,

      [theme.breakpoints.down("sm")]: {
        textAlign: 'center'
      },

  },
  footerMenuHeader: {
    fontSize: 18,

      [theme.breakpoints.down("sm")]: {
        textAlign: 'center'
      },

  },
  footerContainer: {
    padding: 0,
  },
  footerList: {
    padding: 0,
    '& li': {
      [theme.breakpoints.down("sm")]: {
        alignItems: 'center',
        justifyContent: 'center'
      },
      padding: 0,
      '& a': {
        color: '#fff',
        marginBottom: '.5rem',
        fontFamily: 'Lato',
      }
    }
  }
}),
);

export default function FooterDefault(props) {
  const {menu} = props;
  const router = useRouter();

  const classes = useStyles();
  const logoReverse = '/assets/baywood-greens-logo.svg';
  const logoStrikeout = '/assets/baywood-white-text.svg';
  let d=0, i;

  const main_menu = (
    <>
      {menu && menu['#items'] && (
        <>
          { Object.entries(menu['#items']).map(([key, item]) => {
              let inner_lvl1 = [], inner_lvl2 = [], uniqueID1;
              d += 1;
              let col_count = Object.entries(menu['#items']).length;
              let uniqueID = key.substring(key.length - 7);

              inner_lvl2 = [];
              if (item.below && (item.below.length > 0 || Object.keys(item.below).length)) {
                for (const [key1, item1] of Object.entries(item.below)) {
                  let parent = null;
                  uniqueID1 = key1.substring(key1.length - 7);
                  if (key1.includes('menu_link_content:')) {
                    parent = {
                      title: item1.title,
                      href: item1.url,
                      key: uniqueID1,
                    };

                    if (item1.below && (item1.below.length > 0 || Object.keys(item1.below).length)) {
                      for (const [key2, item2] of Object.entries(item1.below)) {
                        if (key2.includes('menu_link_content:')) {
                          let uniqueID2 = key2.substring(key2.length - 7);
                          inner_lvl2.push({
                            title: item2.title,
                            href: item2.url,
                            key: uniqueID2,
                          });
                        }
                      }
                      parent['below'] = inner_lvl2;
                    }
                    inner_lvl1.push(parent);
                    inner_lvl2 = [];
                  }
                }
              }

              return (
                <Grid item xs={12} md={d == col_count ? 1 : 2} key={`acc-${uniqueID}`}>
                  <Box mt={7} color='#fff' display='flex' flexDirection='column' className={classes.boxCenter}>
                    {
                      inner_lvl1.map((item11, key11) => {
                        i = i ? (i+1) : 1;
                        return (
                          <Fragment key={`f-${key11}`}>
                            <Typography className={classes.footerMenuHeader}  key={`par-${key11}`}>
                              <strong>
                                {item11?.title}
                              </strong>
                            </Typography>
                            <List className={classes.footerList} key={`list-${key11}`}>
                              {item11?.below?.map((item12, key12) => {
                                return (
                                  <ListItem key={key12}>
                                    <Link href={item12.href}
                                          title={item12.title}>{item12.title}</Link>
                                  </ListItem>
                                )
                              })
                              }
                            </List>
                            {item11?.below?.length > 1 && item11?.below?.length != i &&
                              <Box mt={7} />
                            }
                          </Fragment>
                        )
                      })
                    }
                  </Box>
                </Grid>
              );
              inner_lvl1 = [];
            })
          }
        </>
      )}
    </>
  );

  return (
    <Box display="block" displayPrint="none">
    <footer id="site-footer" className={classes.footer}>
      {!router?.asPath.includes('/golf') &&
        <FooterBanner
          title='Request a Brochure'
          body='Request a Copy of our Community Brochure. Stay Up-to-Date with all of our Latest Information.'
          buttonText='Request a Brochure'
          url='#'
          backgroundColor='#3F3F3F'
          color='#ffffff'
          formUrl= 'https://fs3.formsite.com/g4S24p/7bpmt8klcc/index.html'
          formHeight= '1800'
        />
      }
      {router?.asPath.includes('/golf') &&
        <FooterBanner
          title='Download our Mobile App'
          body='The Baywood Greens Golf App includes a GPS enabled yardage guide, 3D flyovers, live scoring and much more!'
          buttonText='DOWNLOAD OUR APP'
          url='/download-our-app'
          backgroundColor='#3F3F3F'
          color='#ffffff'
        />
      }
      <Box bgcolor='primary.main'>
        <Container className={classes.footerTop}>
          <Grid container>
            <Grid item xs={12} md={3} lg={2} className={classes.topGrid}>
            <Box className={classes.topLogo}>
              <Logo width={150} height={108} alt='Baywood Greens' name={logoStrikeout} />
            </Box>
            </Grid>
            <Grid item xs={12} md={3} lg className={classes.topGrid}>
              <Link href='tel:3029456280' underline='none'>
                <Typography variant='h6' component='h6'>
                  Home Sales
                  <br />
                  <span>
                    (302) 945-6280
                  </span>
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={12} md={3} lg={4} className={classes.topGrid}>
              <Link href='tel:888-844-2254' underline='none'>
                <Typography variant='h6' component='h6'>
                  Pro Shop at Baywood Greens
                  <br />
                  <span>
                    (888) 844-2254
                  </span>
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={12} md={3} lg className={classes.topGrid}>
              <Link href='tel:302-947-9225' underline='none'>
                <Typography variant='h6' component='h6'>
                  Clubhouse at Baywood
                  <br />
                  <span>
                    (302) 947-9225
                  </span>
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box bgcolor='#3F3F3F'>
        <Container className={classes.footerContainer}>
          <Grid container>
            <Grid item xs={12} md={3} lg={2}>
              <Box mt={7}>
                <Box className={classes.bodyLogo}>
                  <Logo width={156} height={120} alt='Baywood Greens.' name={logoReverse} />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={3} >
              <Box pr={3} mt={7} color='#fff' display='flex' flexDirection='column' className={classes.boxCenter}>
                <Typography className={classes.footerMenuHeader}>
                  <strong>
                    HOME SALES GALLERY
                  </strong>
                </Typography>
                <Typography className={classes.footerMenuContent}>
                  Open Daily 10AM - 5PM
                  <br />
                  32851 Greens Way
                  <br />
                  Long Neck, DE 19966
                </Typography>
                <Typography className={classes.footerMenuHeader}>
                  <strong>
                    GOLF COURSE PRO SHOP
                  </strong>
                </Typography>
                <Typography className={classes.footerMenuContent}>
                  In-Season 6AM - 7PM,<br />Off-Season: 8AM - 5PM
                  <br />
                  32267 Clubhouse Way
                  <br />
                  Long Neck, DE 19966
                </Typography>
                <Typography className={classes.footerMenuHeader}>
                  <strong>
                    CLUBHOUSE AT BAYWOOD GREENS
                  </strong>
                </Typography>
                <Typography className={classes.footerMenuContent}>
                  Monday Through Thursday: 11AM - 8PM
                  <br />
                  Friday Through Saturday: 11Am - 9PM
                  <br />32267 Clubhouse Way
                  <br />
                  Long Neck, DE 19966
                </Typography>
              </Box>
            </Grid>
            {main_menu}
            <Grid item xs={12} md={12} lg={2}>
              <Box mt={7} color='#fff'>
                <Typography align='center' className={classes.footerMenuHeader}>
                  <strong>
                    SOCIAL MEDIA
                  </strong>
                </Typography>
                  <SocialAccounts />
              </Box>
            </Grid>
          </Grid>
          </Container>
        <Box py={1} display='flex' justifyContent='center'>
          <Typography component='span' className={classes.footerCopyright}>
            ©Copyright {new Date().getFullYear()} Baywood Greens
          </Typography>
        </Box>
      </Box>
    </footer>
    </Box>
  );
}
