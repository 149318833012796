/* CAROUSEL UTILIZED FOR PARAGRAPH OF TYPE  "NODE_CAROUSEL" or FOR CAROUSELS WITH
   FEWER THAN 5 IMAGES
*/
import React from 'react';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import CarouselHOC from '../CarouselHOC';

// To override/merge styles in CarouselHOC:
const useStyles = makeStyles((theme) => createStyles({
  rootCarousel: {
    "& img": {
      maxWidth: '100%',
      [theme.breakpoints.up("md")]: {
        minHeight: '480px !important',
      },
    },
    '& .slick-center .gallery-img': {
      textAlign: 'center',
    },
    '& .slick-dots': {
      bottom: '1.5rem',
      '& li button:before': {
        fontSize: 24,
        color: theme.palette.primary.main,
        opacity: 1
      },
      '& li.slick-active button:before': {
        color: theme.palette.warning.main
      },
      '& li:hover button:before': {
        color: theme.palette.warning.main
      }
    }
  },
  galleryImage: {
    '& span': {
      maxWidth: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      height: 560,
    }
  },
  thumbImage: {
    height: 'auto',
    border: '2px solid white',
  },
}));

function CarouselContentSlider(props) {
  let {
    customClass,
    slides,
    showLight,
    showThumb,
    featured,
    slickSett,
    slickNavSett,
    children,
    margin,
    marginUnit,
    linkOut,
    gutter,
    captions
  } = props;

  if (!props.slides && !props.children) {
    return null;
  }

  showThumb = showThumb || {
    smUp: true
  };
  customClass = customClass || '';
  const HOCstyles = useStyles();
  console.log('CarouselContentSlider sett', slickSett);

  return (
      <div className={customClass}>
        <CarouselHOC contentCarousel={true} classes={HOCstyles} slides={slides}
                     featured={featured} slickSett={slickSett}
                     showLight={showLight} showThumb={showThumb} margin={margin}
                     marginUnit={marginUnit}
                     linkOut={linkOut} gutter={gutter}
                     slickNavSett={slickNavSett} captions={captions}>
          {children}
        </CarouselHOC>
      </div>
  );
}

export default CarouselContentSlider;
